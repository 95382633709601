import { Static, TSchema, Type } from "@sinclair/typebox"
import {
  FEATURES,
  FLEX_ASSET_TYPE,
  FLEX_REFERENCE_TYPE,
  ORGANIZATION_STATUS,
  PRODUCT_ENTITLEMENT_STATUS,
  ROLES,
  STUDENT_TYPE,
  PRO_PACK_STATUS,
} from "~/enums"

type Timestamped = Static<typeof TimestampBox>
const TimestampBox = Type.Partial(
  Type.Object({
    createdAt: Type.Optional(Type.String()),
    updatedAt: Type.Optional(Type.String()),
  }),
)

export type EmailPayload = Static<typeof EmailPayloadBox>
const EmailPayloadBox = Type.Intersect([
  Type.Object({
    id: Type.Number(),
    email: Type.String(),
    verifiedAt: Type.Optional(Type.String()),
  }),
  TimestampBox,
])

export type OIDCLoginPayload = {
  userId: string
  accessToken: string
  status: boolean
  cookie: string
}

export type Role = Static<typeof RoleBox>
const RoleBox = Type.Union(Object.values(ROLES).map(l => Type.Literal(l)))

export type RolePayload = Static<typeof RolePayloadBox>
const RolePayloadBox = Type.Object({
  id: Type.Number(),
  role: RoleBox,
  isAdmin: Type.Boolean(),
  isWordpressRole: Type.Boolean(),
})

export type EntitlementStatus = Static<typeof EntitlementStatusBox>
const EntitlementStatusBox = Type.Union(
  Object.values(PRODUCT_ENTITLEMENT_STATUS).map(l => Type.Literal(l)),
)

export type EntitlementPayload = Static<typeof EntitlementPayloadBox>
const GenericEntitlementPayloadBox = <T extends TSchema>(edges: T) =>
  Type.Object({
    id: Type.Number(),
    productId: Type.Number(),
    productName: Type.String(),
    productStatus: EntitlementStatusBox,
    productOriginID: Type.Number(),
    productOriginType: Type.String(),
    entitlementAdmin: Type.String(),
    edges: Type.Optional(edges),
  })

export type StudentType = Static<typeof StudentTypeBox>
const StudentTypeBox = Type.Union(
  Object.values(STUDENT_TYPE).map(l => Type.Literal(l)),
)

export type UserDataPayload = Static<typeof UserDataPayloadBox>
const GenericUserDataPayloadBox = <T extends TSchema>(edges: T) =>
  Type.Intersect([
    Type.Object({
      id: Type.String(),
      authProfileID: Type.Optional(Type.String()),
      stripeCustomerId: Type.Optional(Type.String()),
      primaryEmailId: Type.Number(),
      wordpressId: Type.Optional(Type.Number()),
      hubspotId: Type.Optional(Type.Number()),
      hubspotPrimaryCompanyId: Type.Optional(Type.Number()),
      brightSpaceHigherEducationID: Type.Optional(Type.Number()),
      brightSpaceProDevID: Type.Optional(Type.Number()),
      firstName: Type.String(),
      lastName: Type.String(),
      campusCafeId: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
      studentType: StudentTypeBox,
      degreeSeekingStatus: Type.Optional(Type.Boolean()),
      underGradDegreeConfirmed: Type.Optional(Type.Boolean()),
      lastLoggedIn: Type.Optional(Type.String()),
      edges: Type.Optional(edges),
    }),
    TimestampBox,
  ])

export type ProfileDataPayload = Timestamped & {
  id: number
  teachingRole?: string
  stateStandard?: string
  gradesTaught?: string[]
  highestEducationAchieved?: string
  streetAddress?: string
  streetAddress2?: string
  schoolDistrictName?: string
  stripeCustomerId?: string
  cityTown?: string
  stateRegion?: string
  country?: string
  postalCode?: string
  phoneNumber?: string
  dateOfBirth?: string
}

export type LoginDataPayload = {
  accessToken: string
  userData: {
    id: string
    primaryEmailId: number
    hubspotId: number
    firstName: string
    lastName: string
    schoolAdministrator: boolean
    underGradDegreeConfirmed: boolean
    brightSpaceProDevID: number
    createdAt: string
    lastLoggedIn: string
    updatedAt: string
    edges: {
      emails: {
        id: number
        email: string
        verifiedAt: string
        createdAt: string
        updatedAt: string
        edges: unknown
      }[]
      organization: {
        id: number
        name: string
        districtAdmin: string
        supportAdmin: string
        status: string
        createdAt: string
        updatedAt: string
        edges: unknown
      }
    }
  }
}

export type Feature = Static<typeof FeatureBox>
export const FeatureBox = Type.Union(
  Object.values(FEATURES).map(l => Type.Literal(l)),
)
type FeatureState = {
  id: number
  feature: {
    id: number
    name: Feature
    type: string
  }
  feature_state_value: unknown
  enabled: boolean
}
export type Trait = {
  trait_key: string
  trait_value: string | null
}

export type FlagsPayload = FeatureState[]

export type UserTraitsPayload = {
  flags: FeatureState[]
  traits: Trait[]
}

export type UserSubscriptionPayload = {
  id: number
  userId: string
  stripeCustomerId: string
  productId: number
  startsAt: string
  expiresAt: string
  active: boolean
  stripeSubId: string
  productName: string
  recurrence: string
  purchasedBy: string
  enterprise: boolean
  activeStudent: boolean
  createdAt: string
  updatedAt: string
}

export type UserTicketPayload = {
  id: number
  userId: string
  stripeCustomerId: string
  productId: number
  productName: string
  purchasedBy: string
  createdAt: string
  updatedAt: string
}

export type SetUserTraitsPayload = (Trait & {
  identity: { identifier: string }
})[]

export type SSNPayload = {
  partialSSN: string
  vaultToken: string
}

export type OrganizationStatus = Static<typeof OrganizationStatusBox>
const OrganizationStatusBox = Type.Union(
  Object.values(ORGANIZATION_STATUS).map(l => Type.Literal(l)),
)
const GenericOrgAdminsBox = <T extends TSchema>(edges: T) =>
  Type.Object({
    districtAdminUser: Type.Optional(GenericUserDataPayloadBox(edges)),
    supportAdminUser: Type.Optional(GenericUserDataPayloadBox(edges)),
  })

export type OrganizationPayload = Static<typeof OrganizationPayloadBox>
export const OrganizationPayloadBox = Type.Intersect([
  Type.Object({
    id: Type.Number(),
    name: Type.String(),
    status: OrganizationStatusBox,
    freeTrial: Type.Optional(Type.Boolean()),
    hubspotAccountId: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    districtAdmin: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    supportAdmin: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    maxFlexEntitlementCount: Type.Optional(Type.Number()),
    maxProEntitlementCount: Type.Optional(Type.Number()),
    hasDAReportAccess: Type.Optional(Type.Boolean()),
    license_summary: Type.Optional(
      Type.Array(
        Type.Object({
          organizationID: Type.Number(),
          licenseCount: Type.Number(),
          productName: Type.String(),
          productStatus: EntitlementStatusBox,
        }),
      ),
    ),
  }),
  GenericOrgAdminsBox(
    Type.Object({
      entitlement: Type.Optional(Type.Array(EmailPayloadBox)),
      emails: Type.Array(EmailPayloadBox),
    }),
  ),
  TimestampBox,
])

const EntitlementPayloadBox = GenericEntitlementPayloadBox(
  Type.Object({
    organization: Type.Optional(OrganizationPayloadBox),
  }),
)

export const UserDataPayloadBox = GenericUserDataPayloadBox(
  Type.Object({
    emails: Type.Array(EmailPayloadBox),
    roles: Type.Optional(Type.Array(RolePayloadBox)),
    entitlement: Type.Optional(
      Type.Array(
        GenericEntitlementPayloadBox(
          Type.Object({ organization: Type.Optional(OrganizationPayloadBox) }),
        ),
      ),
    ),
    organization: Type.Optional(OrganizationPayloadBox),
  }),
)

export type OrgUserPayload = Static<typeof OrgUserPayloadBox>
const OrgUserPayloadBox = Type.Intersect([
  GenericOrgAdminsBox(
    Type.Object({
      entitlement: Type.Optional(
        Type.Array(
          GenericEntitlementPayloadBox(
            Type.Object({
              organization: Type.Optional(OrganizationPayloadBox),
            }),
          ),
        ),
      ),
      organization: Type.Optional(OrganizationPayloadBox),
    }),
  ),
  GenericUserDataPayloadBox(
    Type.Object({
      entitlement: Type.Optional(
        Type.Array(
          GenericEntitlementPayloadBox(
            Type.Object({
              organization: Type.Optional(OrganizationPayloadBox),
            }),
          ),
        ),
      ),
      emails: Type.Array(EmailPayloadBox),
      organization: Type.Optional(OrganizationPayloadBox),
    }),
  ),
])

export type EntitlementUpdatePayload = {
  userId: string
  productName: string
  productStatus: EntitlementStatus
  additional_info?: string
  info?: string
  edges?: { organization?: OrganizationPayload }
}

export type BulkUploadPayload = UserDataPayload & {
  entitlementUpdates: (EntitlementUpdatePayload & {
    info: string
    index: number
  })[]
}

export type ProductPayload = {
  name: string
}

export type ProductPricePayload = Static<typeof ProductPricePayloadBox>
export const ProductPricePayloadBox = Type.Object({
  active: Type.Boolean(),
  billing_scheme: Type.String(),
  created: Type.Integer(),
  currency: Type.String(),
  deleted: Type.Boolean(),
  id: Type.String(),
  livemode: Type.Boolean(),
  lookup_key: Type.Optional(Type.String()),
  metadata: Type.Object({
    ItemCode: Type.Optional(Type.String()),
    IsTaxInclusive: Type.Optional(Type.Boolean()),
  }),
  nickname: Type.String(),
  object: Type.Literal("price"),
  product: Type.Object({
    active: Type.Boolean(),
    attributes: Type.Array(Type.String()),
    caption: Type.String(),
    created: Type.Integer(),
    deactivate_on: Type.Optional(Type.String()),
    deleted: Type.Boolean(),
    description: Type.String(),
    id: Type.String(),
    images: Type.Array(Type.String()),
    livemode: Type.Boolean(),
    metadata: Type.Object({
      product_description: Type.Optional(Type.String()),
    }),
    name: Type.String(),
    object: Type.Literal("product"),
    package_dimensions: Type.Optional(Type.String()),
    shippable: Type.Boolean(),
    statement_descriptor: Type.Optional(Type.String()),
    tax_code: Type.Optional(Type.String()),
    type: Type.String(),
    unit_label: Type.Optional(Type.String()),
    updated: Type.Integer(),
    url: Type.Optional(Type.String()),
  }),
  recurring: Type.Optional(Type.String()),
  tax_behavior: Type.String(),
  tiers: Type.Optional(Type.String()),
  tiers_mode: Type.Optional(Type.String()),
  transform_quantity: Type.Optional(Type.String()),
  type: Type.String(),
  unit_amount: Type.Integer(),
  unit_amount_decimal: Type.String(),
})

export type FLEXReferenceType = Static<typeof FLEXReferenceTypeBox>
const FLEXReferenceTypeBox = Type.Union(
  Object.values(FLEX_REFERENCE_TYPE).map(l => Type.Literal(l)),
)

export type FLEXReference = Static<typeof FLEXReferenceBox>
const FLEXReferenceBox = Type.Object({
  id: Type.String(),
  title: Type.String(),
  type: FLEXReferenceTypeBox,
  href: Type.String(),
})

export type FLEXAssetType = Static<typeof FLEXAssetTypeBox>
export const FLEXAssetTypeBox = Type.Union(
  Object.values(FLEX_ASSET_TYPE).map(l => Type.Literal(l)),
)

export type FLEXAssetPayload = Static<typeof FLEXAssetPayloadBox>
export const FLEXAssetPayloadBox = Type.Intersect([
  Type.Object({
    id: Type.String(),
    title: Type.String(),
    slug: Type.String(),
    subtitle: Type.String(),
    type: FLEXAssetTypeBox,
    href: Type.String(),
    videoId: Type.Optional(Type.String()),
    thumbnail: Type.String(),
    download: Type.String(),
    references: Type.Optional(Type.Array(FLEXReferenceBox)),
    assessmentType: Type.Optional(Type.String()),
    resourceType: Type.Optional(Type.String()),
    level: Type.Optional(Type.Array(Type.String())),
    gradeLevels: Type.Optional(Type.Array(Type.String())),
    descriptiveTags: Type.Optional(Type.Array(Type.String())),
  }),
  TimestampBox,
])

const FLEXEssentialQuestionBox = Type.Object({
  _id: Type.String(),
  question: Type.String(),
})

export type FLEXIndividualLessonPayload = Static<
  typeof FLEXIndividualLessonPayloadBox
>
export const FLEXIndividualLessonPayloadBox = Type.Object({
  id: Type.String(),
  title: Type.String(),
  slug: Type.String(),
  type: FLEXAssetTypeBox,
  description: Type.Optional(Type.String()),
  essentialQuestions: Type.Optional(Type.Array(FLEXEssentialQuestionBox)),
  thumbnail: Type.String(),
  references: Type.Optional(Type.Array(FLEXReferenceBox)),
  objectives: Type.Array(Type.String()),
  strategies: Type.Array(Type.String()),
  materials: Type.Array(Type.String()),
  steps: Type.Array(
    Type.Object({
      title: Type.String(),
      text: Type.String(),
      images: Type.Array(Type.String()),
    }),
  ),
  assets: Type.Array(FLEXAssetPayloadBox),
  standards: Type.Array(
    Type.Object({
      body: Type.String(),
      title: Type.String(),
      description: Type.String(),
      text: Type.String(),
      href: Type.String(),
    }),
  ),
  teacherPrepTime: Type.Number(),
  demonstrationTime: Type.Number(),
  studentWorkTime: Type.Number(),
})

export type FLEXCollectionSearchPayload = Static<
  typeof FLEXCollectionSearchPayloadBox
>
export const FLEXCollectionSearchPayloadBox = Type.Object({
  id: Type.String(),
  title: Type.String(),
  href: Type.String(),
  thumbnails: Type.Array(
    Type.Object({
      src: Type.String(),
      alt: Type.Optional(Type.String()),
    }),
  ),
  assets: Type.Array(FLEXAssetPayloadBox),
})

export type FLEXIndividualCollectionPayload = Static<
  typeof FLEXIndividualCollectionPayloadBox
>
export const FLEXIndividualCollectionPayloadBox = Type.Object({
  id: Type.String(),
  title: Type.String(),
  description: Type.String(),
  references: Type.Array(FLEXReferenceBox),
  assets: Type.Array(FLEXAssetPayloadBox),
})

export type PROPackPayload = Static<typeof PROPackPayloadBox>
export const PROPackPayloadBox = Type.Object({
  id: Type.String(),
  title: Type.String(),
  topic: Type.String(),
  thumbnail: Type.String(),
  resourceCount: Type.Number(),
  videoCount: Type.Number(),
  assessmentCount: Type.Number(),
  pdHours: Type.Number(),
  bsOrgId: Type.Number(),
  grades: Type.Optional(Type.Array(Type.String())),
  proConnections: Type.Optional(Type.Array(Type.String())),
  proMediums: Type.Optional(Type.Array(Type.String())),
  facilitators: Type.Optional(Type.Array(Type.String())),
  topicColor: Type.Optional(Type.String()),
})

export type ProPackAnalyticsPayload = {
  viewContext?: "search result card" | "autocomplete result"
} & Pick<
  PROPackPayload,
  | "title"
  | "bsOrgId"
  | "topic"
  | "pdHours"
  | "videoCount"
  | "resourceCount"
  | "grades"
  | "proConnections"
  | "proMediums"
  | "facilitators"
>

export type FLEXFilterOption = Static<typeof FLEXFilterOptionBox>
export const FLEXFilterOptionBox = Type.Object({
  value: Type.String(),
  label: Type.String(),
  color: Type.Optional(Type.String()),
})

export type FLEXSearchResult<T> = {
  filters: Record<string, FLEXFilterOption[]>
  filterNames: Record<string, string>
  results: T[]
  pageCount: number
  resultCount: number
  userStandard?: string
  meta?: {
    resultsCountByDocType: {
      collections: number
      lessonPlans: number
      resources: number
      videos: number
      assessments: number
    }
  }
}

export type ImplementationPayload = Static<typeof ImplementationPayloadBox>
export const ImplementationPayloadBox = Type.Object({
  id: Type.String(),
  title: Type.String(),
  thumbnail: Type.String(),
  url: Type.String(),
  category: Type.String(),
})

export type ImplementationSearchResult = Static<
  typeof ImplementationSearchResultBox
>
export const ImplementationSearchResultBox = Type.Object({
  groups: Type.Array(Type.String()),
  results: Type.Array(ImplementationPayloadBox),
})

export type ImplementationDetailPayload = Static<
  typeof ImplementationDetailPayloadBox
>
export const ImplementationDetailPayloadBox = Type.Object({
  name: Type.String(),
  description: Type.Any(),
  resources: Type.Array(
    Type.Object({
      text: Type.String(),
      link: Type.String(),
    }),
  ),
  thumbnail: Type.String(),
  video: Type.Optional(Type.String()),
})

export type TrackingEventType = Static<typeof TrackingEventTypeBox>
export const TRACKING_EVENT_TYPES = {
  VIEW: "Page View",
  CLICK: "Button Click",
  DOWNLOAD: "Download",
  LINK_CLICK: "Link Click",
  BOOKMARK: "Bookmark",
} as const
export const TrackingEventTypeBox = Type.Union(
  Object.values(TRACKING_EVENT_TYPES).map(l => Type.Literal(l)),
)

export type TrackingPayloadType = Static<typeof TrackingPayloadBox>
export const TrackingPayloadBox = Type.Object({
  event_description: Type.String(),
  event_page_id: Type.String(),
  event_type: TrackingEventTypeBox,
  event_time: Type.String(),
  user_id: Type.String(),
})

const NowConfScheduleOptions = Type.Union([
  Type.Literal("scheduled"),
  Type.Literal("available"),
  Type.Literal("unavailable"),
])
export type NOWConfPayloadType = Static<typeof NOWConfPayloadBox>
export const NOWConfPayloadBox = Type.Object({
  id: Type.Number(),
  productName: Type.String(),
  eventDate: Type.String(),
  eventEndDate: Type.Optional(Type.String()),
  clockHours: Type.Number(),
  afterPassUrl: Type.Optional(Type.String()),
  afterPassPassword: Type.Optional(Type.String()),
  afterPassScheduledStart: Type.Optional(Type.String()),
  afterPassScheduledEnd: Type.Optional(Type.String()),
  afterPassAvailability: Type.Optional(NowConfScheduleOptions),
  saleScheduledStart: Type.Optional(Type.String()),
  saleScheduledEnd: Type.Optional(Type.String()),
  saleAvailability: Type.Optional(NowConfScheduleOptions),
})

export type FLEXCurriculumUnitType = Static<typeof FLEXCurriculumUnitBox>
export const FLEXCurriculumUnitBox = Type.Object({
  unitTitle: Type.String(),
  unitDescription: Type.String(),
  unitObjectives: Type.Array(Type.String()),
  unitContent: Type.Array(
    Type.Union([FLEXIndividualLessonPayloadBox, FLEXAssetPayloadBox]),
  ),
})

export type FLEXCurriculumType = Static<typeof FLEXCurriculumBox>
export const FLEXCurriculumBox = Type.Object({
  createdAt: Type.String(),
  id: Type.String(),
  docType: Type.String(),
  updatedAt: Type.String(),
  name: Type.String(),
  description: Type.String(),
  slug: Type.String(),
  thumbnail: Type.String(),
  references: Type.Optional(Type.Array(FLEXReferenceBox)),
  descriptiveTags: Type.Optional(Type.Array(Type.String())),
  units: Type.Array(FLEXCurriculumUnitBox),
})

export type FLEXBookmarkCreateResponse = Static<
  typeof FLEXBookmarkCreateResponseBox
>
export const FLEXBookmarkCreateResponseBox = Type.Object({
  id: Type.String(),
  asset_id: Type.String(),
  createdAt: Type.String(),
  updatedAt: Type.String(),
})

export type FLEXBookmarkByAssetResponse = Static<
  typeof FLEXBookmarkByAssetResponseBox
>
export const FLEXBookmarkByAssetResponseBox = Type.Object({
  id: Type.String(),
  asset_id: Type.String(),
  createdAt: Type.String(),
  updatedAt: Type.String(),
})

export const FLEXBookmarkBox = Type.Object({
  id: Type.Number(),
  asset_id: Type.String(),
  createdAt: Type.String(),
  updatedAt: Type.String(),
  edges: Type.Any(),
  content: Type.Object({
    id: Type.String(),
    title: Type.String(),
    subtitle: Type.String(),
    type: Type.String(),
    assessmentType: Type.Optional(Type.String()),
    resourceType: Type.Optional(Type.String()),
    href: Type.String(),
    thumbnail: Type.String(),
    references: Type.Array(
      Type.Object({
        id: Type.String(),
        title: Type.String(),
        type: Type.String(),
        href: Type.String(),
      }),
    ),
    gradeLevels: Type.Array(Type.String()),
    descriptiveTags: Type.Array(Type.String()),
  }),
})

export const FLEXBookmarksResponseBox = Type.Array(FLEXBookmarkBox)

export type FLEXBookmarksResponse = Static<typeof FLEXBookmarksResponseBox>

const FlexMetrics = Type.Object({
  teacherMaterialsAccessed: Type.Number(),
  studentMaterialsAccessed: Type.Number(),
})

const TrendingTopic = Type.Object({
  engagement: Type.Number(),
  topic: Type.String(),
})

const ProMetrics = Type.Object({
  teacherEngagement: Type.Number(),
  trendingTopics: Type.Array(TrendingTopic),
})

export type OrganizationProductMetrics = Static<
  typeof OrganizationProductMetricsPayloadBox
>
export const OrganizationProductMetricsPayloadBox = Type.Object({
  flexMetrics: FlexMetrics,
  proMetrics: ProMetrics,
})

const GetUserPermissionsResponseBox = Type.Object({
  userId: Type.String(),
  permissions: Type.Array(Type.String()),
})

export type GetUserPermissionsResponse = Static<
  typeof GetUserPermissionsResponseBox
>

export type ProPackStatus = Static<typeof ProPackStatusBox>
const ProPackStatusBox = Type.Union(
  Object.values(PRO_PACK_STATUS).map(l => Type.Literal(l)),
)

const UserProPackProgressBox = Type.Object({
  OrgUnitID: Type.Number(),
  Status: ProPackStatusBox,
})

const GetUserProPackProgressResponseBox = Type.Object({
  proPackProgress: Type.Array(UserProPackProgressBox),
})

export type GetUserProPackProgressResponse = Static<
  typeof GetUserProPackProgressResponseBox
>

export type UserProPackProgress = Static<typeof UserProPackProgressBox>
