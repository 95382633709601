import { decode, JwtPayload } from "jsonwebtoken"
import { GetServerSidePropsContext } from "next"
import { ApiClient, ClientFromContext } from "@/core/services/aoeu"
import { FlagsPayload } from "~/services/aoeu/models"
import logger from "~/util/logger"

const getEnabledFeatureNames = (features: FlagsPayload): string[] =>
  features.flatMap(feature =>
    feature.enabled ? [feature.feature.name] : [],
  ) ?? []

type AoeuJwtPayload = JwtPayload & {
  userId?: string
  featureServiceId?: string
}

const decodeJwt = (jwt: string): AoeuJwtPayload | null => {
  try {
    return decode(jwt, { json: true })
  } catch (error) {
    return null
  }
}

/**
 * Retrieve enabled feature for a given user given
 * a GetServerSidePropsContext object.
 *
 * This is a wrapper around `getUserFeatures` that extracts
 * the access token from the context object.
 * If only the access token is available, use `getUserFeatures` directly.
 *
 * @example
 * ```ts
 * export async function getServerSideProps(
 *  context: GetServerSidePropsContext,
 *  ): Promise<GetServerSidePropsResult<PageProps>> {
 *    const features = await getUserFeaturesFromContext(context)
 *    return {
 *      props: {
 *        features: Array.from(features),
 *      },
 *    }
 *  }
 *  ```
 */
export async function getUserFeaturesFromContext(
  context: GetServerSidePropsContext,
): Promise<Set<string>> {
  const accessToken = context?.req?.cookies["aoeu-session"]
  return getUserFeatures(accessToken, context)
}

const featureFlagCache = new Map<string, Set<string>>()
const DEFAULT_CACHE_TTL = 15 * 1000

/**
 * Retrieve enabled features for a given user given an access token.
 * Prefer using `getUserFeaturesFromContext` if you have access to the context object.
 */
export async function getUserFeatures(
  accessToken?: string,
  context?: GetServerSidePropsContext,
): Promise<Set<string>> {
  if (!accessToken) {
    return new Set()
  }

  const api = ClientFromContext(ApiClient, context)
  const decodedToken = decodeJwt(accessToken)

  const featureServiceIdentifier = decodedToken?.featureServiceId

  if (!featureServiceIdentifier) {
    logger
      .withScope({
        tags: {
          caller: "getUserFeaturesFromContext",
          token: accessToken,
        },
      })
      .warn("Failed to properly decode JWT token: No feature service ID found")

    return new Set()
  }

  if (featureFlagCache.has(featureServiceIdentifier)) {
    return featureFlagCache.get(featureServiceIdentifier) ?? new Set()
  }

  const userTraitsResponse = await api.getUserTraits({
    featureServiceId: featureServiceIdentifier,
  })

  const enabledFeaturesForUser = new Set(
    getEnabledFeatureNames(userTraitsResponse.data.flags),
  )

  featureFlagCache.set(featureServiceIdentifier, enabledFeaturesForUser)
  setTimeout(
    () => featureFlagCache.delete(featureServiceIdentifier),
    DEFAULT_CACHE_TTL,
  )

  return enabledFeaturesForUser
}

const permissionsCache = new Map<string, Set<string>>()
const DEFAULT_PERMISSIONS_CACHE_TTL = 15 * 1000

/**
 * Retrieve enabled user permissions for a given user using a GetServerSidePropsContext object.
 *
 * @example
 * ```ts
 * export async function getServerSideProps(
 *  context: GetServerSidePropsContext,
 * ): Promise<GetServerSidePropsResult<PageProps>> {
 *    const permissions = await getUserPermissionsFromContext(context)
 *      return {
 *        props: {
 *          permissions: Array.from(permissions),
 *        },
 *      }
 *  }
 * ```
 */
export async function getUserPermissionsFromContext(
  context: GetServerSidePropsContext,
): Promise<Set<string>> {
  const accessToken = context?.req?.cookies["aoeu-session"]

  if (!accessToken) {
    return new Set()
  }

  const decodedToken = decodeJwt(accessToken)
  const userId = decodedToken?.userId

  if (!userId) {
    return new Set()
  }

  if (permissionsCache.has(userId)) {
    return permissionsCache.get(userId) ?? new Set()
  }

  const api = ClientFromContext(ApiClient, context)
  const permissionsResponse = await api.getUserPermissions(userId)

  permissionsCache.set(userId, new Set(permissionsResponse.data.permissions))
  setTimeout(
    () => permissionsCache.delete(userId),
    DEFAULT_PERMISSIONS_CACHE_TTL,
  )

  return new Set(permissionsResponse.data.permissions)
}
